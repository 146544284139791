const Handlebars = require('handlebars/dist/handlebars.js');

Handlebars.registerHelper('ifeq', function ifeq(a, b, options) {
  if (a === b) { return options.fn(this); }
  return options.inverse(this);
});

Handlebars.registerHelper('toLowerCase', str => str.toLowerCase());

export const extend = (obj, src) => {
  const res = obj;
  Object.keys(src).forEach((key) => { res[key] = src[key]; });
  return res;
};

export default { extend };
