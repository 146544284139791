require('../styles/index.scss');
require('jquery');
require('bootstrap/js/dist/alert.js');
require('bootstrap/js/dist/tab.js');
require('bootstrap/js/dist/util.js');
const Bowser = require('bowser');
const Clipboard = require('clipboard');
const Handlebars = require('handlebars/dist/handlebars.js');
const { AsYouType } = require('libphonenumber-js/bundle/libphonenumber-js.min.js');
const Tooltip = require('tooltip.js/dist/umd/tooltip.min.js');
const { extend } = require('./helpers.js');

const companyState = {
  m2film: {
    web: 'https://m2film.dk',
    phone: '+45 70 21 40 20',
    map: 'https://goo.gl/maps/BA8nZgsoMMynRxMQ6',
  },
  m2animation: {
    web: 'https://m2animation.com',
    phone: '+66 2 185 2272',
    map: 'https://goo.gl/maps/bVpEUcbUq8CQE3g47',
  },
  m2entertainment: {
    web: 'https://m2entertainment.com',
    phone: '+45 70 21 40 20',
    map: 'https://goo.gl/maps/tnqSfhzjWRtNNZTC8',
  },
  duckling: {
    web: 'https://duckling.dk',
    phone: '+45 33 47 01 00',
    map: 'https://goo.gl/maps/xMpuWwSGehrhyAdq5',
  },
};

const state = {
  domain: `${window.location.protocol}//${window.location.host}`,
  company: 'm2film',
  name: 'John Doe',
  title: 'Motion Graphic Designer',
  cellphone: '',
  include_phone: false,
  include_postal: false,
  use_global_logo: false,
  include_about: false,
};

const setQueryStringParameter = (key, value) => {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  // if (!value) {
  //   params.delete(key);
  // }
  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};

const setState = (key, value) => {
  state[key] = value;
  setQueryStringParameter(key, value);
};

const validate = (elm) => {
  if (elm.classList.contains('validate-ignore')) {
    return;
  }

  let valid = true;

  // There has to be a better way!
  if (!elm.value || (elm.value === 'on' && elm.getAttribute('type') === 'checkbox')) {
    if (!elm.hasAttribute('required') || elm.getAttribute('type') === 'checkbox') {
      elm.classList.remove('is-invalid', 'is-valid');
      return;
    }
    valid = false;
  }

  if (elm.classList.contains('validate-number')) {
    const regexp = /^[0-9 +]+$/;
    if (!regexp.test(elm.value)) {
      valid = false;
    }
  }

  if (elm.classList.contains('validate-url')) {
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (!regexp.test(elm.value)) {
      valid = false;
    }
  }

  if (valid) {
    elm.classList.remove('is-invalid');
    elm.classList.add('is-valid');
  } else {
    elm.classList.remove('is-valid');
    elm.classList.add('is-invalid');
  }
};

const displayPreview = () => {
  const source = document.querySelector('#signatureTemplate').innerHTML;
  const template = Handlebars.compile(source);

  document.querySelector('.preview').innerHTML = template(extend(companyState[state.company], state));
};

const onInput = (event) => {
  const { target } = event;
  let { value } = event.target;

  if (target.type === 'checkbox') {
    value = false;
    if (target.checked) {
      value = true;
    }
  }

  if (target.name === 'cellphone') {
    const ayt = new AsYouType('DK');
    ayt.input(value);
    try {
      value = ayt.getNumber().format('INTERNATIONAL');
    } catch (err) {
      // console.log(err);
    }
  }

  setState(event.target.name, value);
  displayPreview();
};

const handleState = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const checkboxes = [
    'include_postal',
    'include_about',
    'include_phone',
    'use_global_logo',
  ];

  urlParams.forEach((value, name) => {
    let val = value;
    const elm = document.querySelector(`#${name}, input[name='${name}']`);

    if (checkboxes.includes(name)) {
      if (val === 'true') {
        val = true;
        elm.setAttribute('checked', 'checked');
      } else {
        val = false;
      }
    } else {
      elm.value = val;
      validate(elm);
    }

    setState(name, val);
  });
};

const handleInputs = () => {
  document.querySelectorAll('input, select').forEach((item) => {
    if (!item.classList.contains('input-ignore')) {
      item.addEventListener('blur', () => {
        validate(item);
      });

      item.addEventListener('input', onInput);
    }
  });
};

const handleClipboard = () => {
  const btn = document.querySelector('.clipboard');
  const tip = new Tooltip(btn, {
    placement: 'top',
    trigger: 'click',
    title: 'Copied!',
  });

  btn.addEventListener('blur', () => {
    tip.dispose();
  });

  const clipboard = new Clipboard('.clipboard');
  clipboard.on('success', (event) => {
    tip.show();
    event.clearSelection();
  });
};

const handleCopyPlaceholder = () => {
  const btns = document.querySelectorAll('.btn-copy-placeholder');
  btns.forEach((item) => {
    item.addEventListener('click', () => {
      const input = item.closest('.form-group').querySelector('.form-control');
      input.value = input.getAttribute('placeholder');
      input.dispatchEvent(new Event('blur'));
      input.dispatchEvent(new Event('input'));
    });
  });
};

const detectBrowser = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies({
    chrome: '>=74',
    mobile: {
      safari: '>=11',
    },
  });

  return isValidBrowser;
};

const init = () => {
  const isValidBrowser = detectBrowser();
  if (!isValidBrowser) {
    const alert = document.querySelector('.browser-warning');
    const alertModal = document.querySelector('.browser-warning-modal');
    alert.classList.add('show');
    alert.classList.remove('d-none');

    alertModal.classList.add('show');
    alertModal.classList.add('d-block');
  }

  handleInputs();
  handleState();
  handleClipboard();
  handleCopyPlaceholder();
  displayPreview();
};

init();
